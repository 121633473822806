@import "_mixins.scss";


button {
    &:focus {
        outline: 0 !important;
        box-shadow: none !important;
    }
}

.p-button {
    @include base-btn;

    border: 1px solid transparent;
    background-color: transparent;
    cursor: pointer;
    font-size: var(--text-sm);
    font-weight: var(--font-bold);
    line-height: var(--input-line-height);
    height: var(--btn-height);
    transition: background-color 0.5s, border-color 0.5s, box-shadow 0.5s;

    &:enabled:hover {
        background-color: transparent;
        border-color: transparent;
        color: var(--icon-default-color)
    }

    &.p-button-primary {
        background-color: var(--primary-color) !important;
        border-color: var(--primary-color);

        &:enabled:hover {
            background-color: var(--primary-color) !important;
            border-color: var(--primary-color);
            color: var(--white-color)
        }
    }

    &.p-button-secondary {
        background-color: var(--secondary-color) !important;

        &:enabled:hover {
            background-color: var(--secondary-color) !important;
        }
    }

    &.p-button-success, &.p-confirm-dialog-accept {
        background-color: var(--success-color) !important;

        &:enabled:hover {
            background-color: var(--success-color) !important;
        }
    }

    &.p-button-warning {
        background-color: var(--warning-color) !important;

        &:enabled:hover {
            background-color: var(--warning-color) !important;
        }
    }

    &.p-button-danger, &.p-confirm-dialog-reject {
        background-color: var(--danger-color) !important;

        &:enabled:hover {
            background-color: var(--danger-color) !important;
        }
    }

    &.p-button-outlined {
        background-color: transparent;
        color: var(--primary-color);
        border: 1px solid var(--primary-color);

        &:enabled:hover {
            background: transparent;
            color: var(--primary-color);
        }
    }


    .p-button-label {
        display: inline-block;
        flex: none;
    }

    // &.p-confirm-dialog-accept {
    //     background-color: var(--success-color) !important;

    //     &:enabled:hover {
    //         background-color: var(--success-color) !important;
    //     }
    // }

    // &.p-confirm-dialog-reject {
    //     background-color: var(--danger-color) !important;

    //     &:enabled:hover {
    //         background-color: var(--danger-color) !important;
    //     }
    // }
}
