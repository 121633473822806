ul {
    padding-left: 0;
    margin: 0;
    list-style: none;
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

a, button {
    transition: 0.5s;
}

iframe {
    border-width: 0;
}

p {
    margin: 0;
}
