:root {
    /* Height */
    --header-height: 56px;
    --header-mobile-height: 48px;

    /* Padding */
    --input-paddings: 3px 5px;

    /* Width */
    --btn-min-width: 36px;

    /* Line Height */
    --input-line-height: 1.0;

    /* Other values */
    --border-default-radius: 6px;
    --btn-height: 30px;
}
