@mixin flex-alignment(
    $display: "",
    $justifyContent: "",
    $alignItems: "",
    $flexDirection: "") {
    @if $display != "" {
        display: $display;
    }

    @if $justifyContent != "" {
        justify-content: $justifyContent;
    }

    @if $alignItems != "" {
        align-items: $alignItems;
    }

    @if $flexDirection != "" {
        flex-direction: $flexDirection;
    }
}

@mixin white-box {
    background-color: var(--white-color);
    border-radius: 6px;
}

@mixin base-btn {
    min-width: var(--btn-min-width);
    display: flex;
    justify-content: center;
    color: var(--white-color);
    padding: var(--input-paddings);
    border-radius: var(--border-default-radius);
}

@mixin caret() {
    &::after {
        display: inline-block;
        margin-left: 7px;
        vertical-align: 0.255em;
        content: "";
        border-top: 5px solid;
        border-right: 5px solid transparent;
        border-bottom: 0;
        border-left: 5px solid transparent;
    }
}

@mixin button-status($pointer-event, $btn-opacity) {
    .p-menuitem-link {
        pointer-events: $pointer-event;
    }

    .p-menuitem-text {
        opacity: $btn-opacity;
    }

    .p-menuitem-icon {
        opacity: $btn-opacity;
    }
}

@mixin approve-preview-btn() {
    color: var(--white-color);
    background-color: var(--button-color);
    white-space: nowrap;
    border-radius: 50px;
    text-transform: uppercase;
    background-image: linear-gradient(45deg, #3e5e8d 50%, transparent 50%);
    background-size: 250% 110%;
    background-position: right bottom;
    transition: all 300ms ease 0ms;
    background-repeat: no-repeat;

    &:enabled:hover {
        background-position: left bottom;
        border-color: #3e5e8d;
    }
}

@mixin popup-notification($mainColor, $backgroundColor) {
    border-left-width: thick;
    border-left-color: $mainColor;

    .p-dialog-content {
        background-color: $backgroundColor;
        color: $mainColor;
    }
}
