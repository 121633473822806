/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    appearance: textfield;
}

input::placeholder {
    color: var(--secondary-color);
}

.p-fluid {
    .p-field {
        margin-bottom: 26px !important;
    }

    label {
        margin-bottom: 0 !important;
    }
}

.p-inputtext {
    padding: var(--input-paddings);
    border-radius: var(--border-default-radius);
    line-height: var(--input-line-height);
    font-size: var(--text-sm);
}

.p-dropdown {
    border-radius: var(--border-default-radius);

    .p-dropdown-items-wrapper {
        border-radius: var(--border-default-radius);

        .p-dropdown-item {
            height: 32px;
            padding: 5px 24px 6px;

            &.p-highlight {
                background-color: var(--primary-color);
            }
        }
    }
}

.p-dropdown .p-dropdown-label {
    border: 1px solid transparent;
}
