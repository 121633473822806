/* Import Roboto fonts */
$roboto-font-path: "~src/assets/fonts";

@import "~roboto-fontface/css/roboto/sass/roboto-fontface-bold.scss";
@import "~roboto-fontface/css/roboto/sass/roboto-fontface-regular.scss";
@import "~roboto-fontface/css/roboto/sass/roboto-fontface-black.scss";

@import url("../../node_modules/primeng/resources/themes/lara-light-blue/theme.css");
@import url("../../node_modules/primeng/resources/primeng.min.css");
@import "node_modules/primeflex/src/_variables.scss";
@import "node_modules/primeflex/src/_grid.scss";
@import "node_modules/primeflex/src/_formlayout.scss";
@import "node_modules/primeflex/src/flexbox/_flexbox.scss";
@import "~primeicons/primeicons.css";

@import "_variables.scss";
@import "_color-variables.scss";
@import "advanced-form.styles.scss";
@import "advanced-button.styles.scss";
@import "advanced-layout.styles.scss";
@import "advanced-header.styles.scss";
@import "advanced-common.styles.scss";
@import "_font-variable.scss";
@import "_font-weight-variable.scss";
@import "../../node_modules/primeflex/primeflex.min.css";
@import "../../node_modules/primeng/resources/themes/saga-blue/theme.css";
@import "../../node_modules/primeng/resources/primeng.min.css";
@import "../../node_modules/quangular/src/scss/styles.scss";

html {
    font-size: 14px;
}

body {
    margin: auto;
    background: var(--gray-lightest-color);
    font-family: 'IBM Plex Sans', sans-serif;
    color: var(--black-color);
    overflow-x: hidden;
    max-width: 1920px;
    overflow-y: scroll;

}

@media only screen and (min-width: 1200px) {
    .container {
        max-width: 1920px;
    }
}

.gray-bg {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
    opacity: 0;
    background-color: black;
    transition: opacity 0.5s;

    &.show {
        opacity: 0.7;
        z-index: 998;
    }
}

.middle-container {
    display: block;
    max-width: 614px;
    margin: 0 auto;
    padding: 0 8px;
}

/* roboto-mono-regular - latin */
@font-face {
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('~src/assets/fonts/roboto-mono-v22-latin/roboto-mono-v22-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('~src/assets/fonts/roboto-mono-v22-latin/roboto-mono-v22-latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.q-currency {
    font-family: 'IBM Plex Sans', sans-serif;
    text-align: right !important;
    display: table-cell !important;
}

.q-currency-header {
    text-align: right !important;
    display: table-cell !important;
}
.q-comments{
    flex: 0 0 420px !important;
    word-break: break-word;
}

.primary-header {
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 25px;
    color: #000000;
}

.secondary-header {
    font-style: normal;
    font-weight: 600;
    font-size: 17.5px;
    line-height: 21px;
    color: #000000;
}

.section-header {
    font-style: normal;
    font-weight: 600;
    font-size: 17.5px;
    line-height: 26px;
    color: #495057;
}

.section-content {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #495057;
}

.primary-textarea {
    border: 1px solid #CED4DA;
    border-radius: 6px;
    width: 777.06px;
    height: 76px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10.5px;
    gap: 14px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
}

.primary-input {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10.5px;
    gap: 14px;

    width: 218px;
    height: 42px;
    background: #FFFFFF;
    border: 1px solid #CED4DA;
    border-radius: 6px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.primary-input {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10.5px;
    gap: 14px;

    width: 218px;
    height: 42px;
    background: #FFFFFF;
    border: 1px solid #CED4DA;
    border-radius: 6px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.primary-input-label {
    width: 197px;
    height: 21px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #495057;
    flex: none;
    order: 0;
    flex-grow: 1;
}

.primary-chips {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10.5px;
    gap: 14px;

    background: #FFFFFF;
    border: 1px solid #CED4DA;
    border-radius: 0px 6px 6px 0px;
    flex: none;
    order: 1;
    flex-grow: 1;
}

.form-error{
    color: #D8000C;
    font-style: normal;
    font-weight: 600;
    margin-top:5px;
    font-size: 14px;
}

.flex-gap {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 12px;
}
.new-commit-cal .p-calendar .p-inputtext
{
    padding: 10.5px;
    width: 335px;
    height: 42px;
    background: #FFFFFF;
    border: 1px solid #CED4DA;
}
.nav{
    &__link {
        font-size: var(--text-base);
        font-weight: var(--font-normal);
    }
}
p-checkbox, .p-datatable .p-datatable-tbody > tr > td, .p-datatable .p-datatable-thead > tr > th
{
    font-size: var(--text-sm);
}
.p-component
{
    font-family: 'IBM Plex Sans', sans-serif;
}

.isDisabled{
    pointer-events: none;
    opacity: 0.5;
}
.isDisabled:hover{
    opacity: 0.6;
}
.capital-call-inputgroup{
    .p-chip{
        margin-top: 0.4rem !important;
        margin-left: 0.28rem;
    }
}
.chip-section{
    align-items: center;
    cursor: text;
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    overflow: hidden;
}
.custom-textarea {
    -moz-appearance: textfield-multiline;
    -webkit-appearance: textarea;
    border: 1px solid #CED4DA;
    border-radius: 6px;
    height: 76px;
    padding: 10.5px;
    width: 900px;
    overflow: auto;
    resize: both;
    color: #495057;
}
#richText{
    border-bottom-style: dashed;
    border-bottom-color: darkgrey;
    border-bottom-width: 1.5px;
}
:focus-visible {
    outline: none;
}
.div-text[placeholder]:empty:before {
    content: attr(placeholder);
    color: #555; 
}

.div-text[placeholder]:empty:focus:before {
    content: "";
}
.active{
    color: blue;
    cursor: pointer;
}
.float-right{
    float: right
}
.cursor{
    cursor: pointer
}

q-file-browser {
    .p-dropdown {
        padding: 11px 0 !important;
        width: 100%;
        .p-dropdown-item.p-highlight {
            background-color: #DEE2E6 !important;
        }
    }

    .p-dialog-footer {
        display: flex;
        justify-content: flex-end;
    }

    .gap-5 {
        gap: 2rem;
    }

    p-toolbar {
        .p-toolbar {
            position: sticky;
            top: 56px;
            z-index: 2;
        }

        input {
            width: 160px !important;
        }
    }

    .path-sec {
        position: sticky;
        top: 110px;
        z-index: 1;
    }

    thead th {
        position: sticky;
        top: 110px;
        z-index: 1;
    }

    &.bc-visible thead th {
        top: 156px;
    }
}
.p-overflow-hidden{
    overflow: scroll !important;
    overflow-x: hidden !important;
}