:root {
    --brand-color: #3e5e8d;
    --primary-color: #3d4a5d;
    --secondary-color: #6c757d;
    --danger-color: #db526b;
    --success-color: #7ec189;
    --warning-color: #ffc107;
    --light-color: #f2f3f4;
    --white-color: #fff;
    --black-color: #212529;
    --black-lighter-color: #31363e;
    --gray-unactive-color: #f7f8f860;
    --gray-color: #6c757d;
    --gray-lighter-color: #ced4da;
    --gray-lightest-color: #f2f2f2;
    --blue-color: #709dc2;
    --button-color: #00be35;
    --icon-default-color: #495057
}
